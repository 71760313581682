import {
  AnalyticsProvider,
  BrowserProvider,
  PageDataProvider,
} from 'components/Contexts';
import { CookieBanner, ErrorPage, Loader } from 'components/Shared';
import { PRODUCT_PAGE_QUERY } from 'graphql/queries';
import { useListenerToBusinessApp } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import queryString from 'query-string';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { PageDataType } from 'types/types';
import { StringParam, useQueryParam } from 'use-query-params';
import { fetchWithCache, getContentLanguage, getValidLang } from 'utils/cache';
import { ipadCheck, mobileCheck, safariCheck } from 'utils/device';
import client from 'utils/graphql-client';
import { getLocaleCode } from 'utils/locale';
import theme, { GlobalStyle, ThemeType, mergeThemes } from 'utils/theme';
import { getGoogleFontsLink } from 'utils/typography';
import * as Sentry from '@sentry/nextjs';

const Mobile = dynamic(() => import('components/Mobile/Page'), {
  ssr: false,
});
const Desktop = dynamic(() => import('components/Desktop/Page'), {
  ssr: false,
});

interface Props {
  mobile: boolean;
  isSafari: boolean;
  preview: boolean;
  pageData: PageDataType | Record<string, never>;
  error: string | null;
  customTheme: ThemeType;
  renderedInsideIframe: boolean;
}

interface ThemingWrapperProps {
  children: ReactNode;
  title: string;
  customTheme: ThemeType;
  renderedInsideIframe: boolean;
}

const ProductPage: FC<Props> = ({
  pageData,
  preview,
  error,
  mobile,
  isSafari,
  customTheme,
  renderedInsideIframe,
}: Props) => {
  const { productId, title, image, contentBlocks, createdBy, webshopUrl } =
    pageData;
  const [statisticsAccepted, setStatisticsAccepted] = useState<boolean>(false);
  const trackingAllowed = !preview && statisticsAccepted;
  const [byQr, setByQr] = useQueryParam('qr', StringParam);
  const [byQrState] = useState<string>(byQr);

  // Show loading when Preview forces refetch
  const { loading, setLoading } = useListenerToBusinessApp();
  useEffect(() => {
    if (loading) setLoading(false);
  }, [pageData]);

  useEffect(() => {
    setByQr(undefined);
  }, []);

  if (error || isEmpty(pageData) || !contentBlocks) {
    return (
      <ThemingWrapper
        title={title}
        renderedInsideIframe={renderedInsideIframe}
        customTheme={customTheme}
      >
        <ErrorPage
          errorMessage="Hmm, looks like there is no content here yet. Try saving your product and checking again!"
          error={error}
        />
      </ThemingWrapper>
    );
  }

  const isIpad = ipadCheck();
  const PageComponent = mobile || isIpad ? Mobile : Desktop;

  return (
    <AnalyticsProvider
      productId={productId}
      trackingAllowed={trackingAllowed}
      preview={preview}
      byQr={byQrState}
      gaTrackingId={createdBy?.gaTrackingId}
    >
      <ThemingWrapper
        title={title}
        renderedInsideIframe={renderedInsideIframe}
        customTheme={customTheme}
      >
        <BrowserProvider
          isMobileServerSide={mobile}
          isSafariServerSide={isSafari}
        >
          <PageDataProvider pageData={pageData as PageDataType}>
            <PageComponent
              title={title || ''}
              image={image}
              contentBlocks={contentBlocks}
              createdBy={createdBy}
              webshopUrl={webshopUrl}
            />
            <CookieBanner
              onGranted={() => setStatisticsAccepted(true)}
              preview={preview}
            />
            {loading && <Loader includeBackground />}
          </PageDataProvider>
        </BrowserProvider>
      </ThemingWrapper>
    </AnalyticsProvider>
  );
};

export default ProductPage;

const ThemingWrapper: FC<ThemingWrapperProps> = ({
  title,
  customTheme,
  renderedInsideIframe,
  children,
}) => (
  <ThemeProvider theme={customTheme}>
    <Head>
      <title>{title || ''}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
    </Head>
    <Script
      strategy="beforeInteractive"
      stylesheets={[
        getGoogleFontsLink(customTheme.typography.header),
        getGoogleFontsLink(customTheme.typography.body),
      ]}
    />
    <GlobalStyle hideScrollBar={renderedInsideIframe} />
    {children}
  </ThemeProvider>
);

export const getServerSideProps: GetServerSideProps = async ({
  query,
  req,
}) => {
  const {
    productPage: slug,
    preview: previewParam,
    locale,
    mobile,
    iframe,
  } = query;

  const preview = previewParam === '1';
  const forceRefresh = req.headers['x-cache-status'] === 'update';
  const isMobile = mobileCheck(req.headers['user-agent']) || mobile === '1';
  const isSafari = safariCheck(req.headers['user-agent']);

  const browserLang = getLocaleCode(req.headers['accept-language']);
  const renderedInsideIframe = iframe === '1' ? true : false;
  const lang = (locale as string) || browserLang;

  const request = () =>
    client.request(PRODUCT_PAGE_QUERY, {
      slug,
      published: !preview,
      lang: getValidLang(lang),
    });

  try {
    const data = await fetchWithCache<{ allProductInfo: PageDataType }>(
      // @ts-ignore
      slug,
      {
        request,
        forceRefresh,
        preview,
        locale: getValidLang(lang),
      }
    );

    // Check if the product has a redirect url
    // This is to support offboarding clients into having the qr-code redirected to there own web page
    if (data.allProductInfo?.offboardingRedirect) {
      return {
        redirect: {
          destination: data.allProductInfo.offboardingRedirect,
          permanent: true,
        },
      };
    }

    const customTheme = mergeThemes(
      theme,
      data.allProductInfo.createdBy.design
    );
    const { currentLang, isDefault } = getContentLanguage(data);
    const props = {
      mobile: isMobile,
      isSafari,
      preview,
      pageData: data.allProductInfo,
      error: null,
      customTheme,
      renderedInsideIframe,
    };

    // case basepath was requested & locale of returned data is not default locale

    if (!locale && !isDefault) {
      const search = queryString.stringify({
        ...query,
        productPage: undefined,
        locale: currentLang,
      });

      return {
        redirect: {
          // @ts-ignore
          destination: `/${slug}?${search}`,
          permanent: false,
        },
        props,
      };
    }

    return { props };
  } catch (error) {
    console.error(error);

    Sentry.captureMessage(error.message, {
      extra: {
        query,
        status: error?.status,
        file: `[productPage].tsx`,
        method: `getServerSideProps`,
      },
    });

    return {
      props: {
        mobile: isMobile,
        isSafari,
        preview,
        pageData: {},
        error: error.message,
        customTheme: theme,
        renderedInsideIframe,
      },
    };
  }
};
